<template>
  <div
    v-if="lesson"
    class="page-lesson"
    :data-class-id="lesson.class"
  >
    <div
      class="page-header"
      :data-class-id="lesson.class"
    >
      <router-link to="/ccmp-pro/tasks" class="page-header__link-back">
        <i class="material-icons">keyboard_arrow_left</i>
      </router-link>
      <div class="page-header__info">
        <span class="page-header__breadcrumbs">{{ ccmpProTitle }}</span>
        <span class="page-header__title">{{ lesson.title }}</span>
      </div>
    </div>

    <div class="page-container-simple">
      <h1 class="page-title">CCMP Pro Content</h1>

      <!-- Pro Content -->
      <div v-if="isCcmpPro">
        <a :href="lesson.quizUrl" target="_blank" class="button-link" :data-class-id="lesson.class">
          <i class="material-icons">question_answer</i>
          <span>Class {{ lesson.class }} - Quiz</span>
          <i class="material-icons icon-right">keyboard_arrow_right</i>
        </a>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'CcmpProContent',

  props: ['id'],

  computed: {
    lesson () {
      return this.$store.state.ccmpData.lessons.find((lesson) => {
        return lesson.id === this.id
      })
    },

    isCcmpPro () {
      return this.$store.state.account.ccmpPro
    },

    ccmpProTitle () {
      return this.$store.state.ccmpData.ccmpProTitles[this.id - 2]
    }
  },

  created () {
  },

  methods: {

    formatTopicId (id) {
      return id
    },

    formatObjectiveId (id) {
      return id
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '@/styles/_variables.scss';

  .page-lesson {
    height: 100%;

    &[data-class-id="1"] {
      background: $orange-light;
    }

    &[data-class-id="2"] {
      background: $jtask-blue-light;
    }

    &[data-class-id="3"] {
      background: $blue-light;
    }

    &[data-class-id="4"] {
      background: $green-light;
    }
  }

  .page-header {
    display: flex;
    flex-direction: row;
  }

  .page-header__link-back {
    flex: 0 0 45px;
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .page-header__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    padding: 15px 0;
  }

  .page-header__breadcrumbs {
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 3px;
  }

  .page-header__title {
    font-size: 15px;
    line-height: 1.4;
  }

  .button-link {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 11px 20px;

    i {
      color: #fff;
      margin-right: 10px;
    }

    .icon-right {
      position: absolute;
      right: 25px;
    }

  }

  .features-list {
    padding-left: 30px;
    margin-bottom: 30px;
  }

</style>
